body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: aliceblue;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-logo {
  margin: 10px 20px;
  width: 70px;
}

.main {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  background-color: rgb(17, 19, 32);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact {
  margin: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact--links {
  display: flex;
}

.contact--links img {
  margin: 5px;
}
.contact--logo {
  height: 30px;
}

.title {
  margin: 30px 20px;
  font-size: calc(1vw + 1vh + 2vmin);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 20px;
  align-items: center;

  background-color: rgb(22, 34, 37);
}

.section {
  max-width: 800px;
  margin: 10px 0px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: rgb(240, 248, 255, 0.3);
}


.section a:link, .section a:visited {
  color: aliceblue;
}

.section a:hover, .section a:active {
  color: rgb(39, 245, 20);
}
